<template>
    <div>
        <div class="box">
            <h3 v-if="current_mandrill_id && current_num && current_subject">
                <a href="" class="" @click.prevent="preview()">
                    <font-awesome-icon :icon="['fas', 'eye']" />
                </a> - {{ current_num }} - {{ current_subject }}
            </h3>
            <CustomTable
                id_table="emails_mandrill_shutter"
                :items="trackings"
                :busy.sync="table_busy"
                primaryKey="mandrilltracking_id"
                :hide_if_empty="true"
                :externSlotColumns="extern_slot_columns"
            >
                <template v-slot:[`custom-slot-cell(mandrilltracking_archived)`]="{ data }">
                    <font-awesome-icon class="text-success" v-if="data.mandrilltracking_archived" :icon="['fal', 'check-circle']" />
                    <font-awesome-icon class="text-danger" v-else :icon="['fal', 'times-circle']" />
                </template>
                <template v-slot:[`custom-slot-cell(mandrilltracking_status)`]="{ data }">
                    {{ data.mandrilltracking_status && data.mandrilltracking_status != '' ? $t('emails.' + data.mandrilltracking_status) : null }}
                </template>
			</CustomTable>
        </div>
        <b-modal size="xl" ref="modelPreview" hide-footer @hidden="base64 = null">
            <template v-slot:modal-title>
                {{ $t("action.previsualiser") }}
            </template>

            <iframe v-if="base64 != null" :srcdoc=" base64" height="1000px" width="100%" style="position:relative"></iframe>
            <div v-else>
                <LoadingSpinner />
            </div>
        </b-modal>
    </div>
</template>


<script type="text/javascript">
    import Invoice from '@/mixins/Emails.js'
    import Vue from 'vue'

    export default {
        name: "Trackings",
        mixins: [Invoice],
        props: ['invoice_id', 'contract_id', 'resa_id'],
        data () {
            return {
                base64: null,
                extern_slot_columns: ['mandrilltracking_archived', 'mandrilltracking_status'],
                table_busy: false,
                current_mandrill_id: null,
                current_num: null,
                current_subject: null,
                trackings: []
            }
		},
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                this.table_busy = true
                let trackings = []
                if(this.invoice_id) {
                    trackings = await this.getTrackingsById(this.invoice_id, 'invoice')
                }
                else if(this.contract_id) {
                    trackings = await this.getTrackingsById(this.contract_id, 'contract')
                }
                else if(this.resa_id) {
                    trackings = await this.getTrackingsById(this.resa_id, 'monte_reservation')
                }
                this.trackings = await this.formatTracking(trackings)
                this.table_busy = false
            },

            async formatTracking(trackings) {
                let tab = []

                for (let i = 0; i < trackings.length; i++) {
                    const element = trackings[i]
                    this.current_mandrill_id = element.mandrilltracking_id
                    this.current_subject = element.mandrilltracking_subject

                    let tab_extern = []
                    for (let j = 0; j < element.extern.length; j++) {
                        const extern_element = element.extern[j];
                        if(element.mandrilltracking_extern_table == 'invoice') {
                            tab_extern.push(extern_element.invoice_num)
                        }
                        else if(element.mandrilltracking_extern_table == 'contract') {
                            tab_extern.push(extern_element.contract_num)
                        }
                        else if(element.mandrilltracking_extern_table == 'quotation') {
                            tab_extern.push(extern_element.quotation_num)
                        }
                        else if(element.mandrilltracking_extern_table == 'tiers') {
                            tab_extern.push(extern_element.tiers_rs)
                        }
                    }

                    if(element.mandrilltracking_status == 'send' && element.mandrilltracking_timestamp) {
                        const date = new Date(element.mandrilltracking_timestamp)
                        element.mandrilltracking_sended = this.formatDate(date)
                    }

                    if(element.date) {
                        element.date = new Date(element.date).toLocaleDateString(Vue.prototype.$i18n.locale()) + ' ' + new Date(element.date).toLocaleTimeString(Vue.prototype.$i18n.locale())
                    }

                    element.extern_num = tab_extern.join(", ")
                    if(!this.current_num) {
                        this.current_num = element.extern_num
                    }

                    tab.push(element)
                }

                return tab
		    },

            async preview() {
                let email = this.trackings.find(elem =>  elem.mandrilltracking_id == this.current_mandrill_id)
                this.base64 = email.mandrilltracking_content
                this.$refs.modelPreview.show()
            },
		},
        components: {
            LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
            CustomTable: () => import('GroomyRoot/components/Table/CustomTable')
        }
    }
</script>
