import Constants from '../../config.local.js'


var EmailsMixin = {
    methods: {
        // Charge la liste des plan comptables depuis la BDD pour l'utilisateur courant
        loadEmailsTracking: async function(from=null, to=null) {
			from = from ? from.toDateInputValue() : ''
			to = to ? to.toDateInputValue() : ''

			let params = {from, to}

			const url = `${this.constructRoute(Constants.EMAILS_TRACKING_URL, params)}?licence_key=${Constants.USER_LICENCE_KEY}`
            const result = await this.$request.request_post_api("EmailsMixin::loadEmailsTracking", url, params, false)
            .catch(error => {
                console.error("EmailsMixin::loadEmailsTrackings => ERROR", error)
                return null
            })
            if(result) return result.retour
            return null
        },

        archiveTrackings: async function(trackings_ids) {
            const url = this.constructRoute(Constants.EMAILS_TRACKING_ARCHIVE_URL, {trackings_ids}) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("EmailsMixin::archiveTrackings", url)
			.catch(error => {
				console.error("EmailsMixin::archiveTrackings => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
        },

        getTrackingsById: async function(id, type) {
            const params = {id, type}

            const url = this.constructRoute(Constants.EMAILS_TRACKING_BY_ID_URL, params) + "?licence_key="+Constants.USER_LICENCE_KEY
			const result = await this.$request.request_get_api("EmailsMixin::archiveTrackings", url)
			.catch(error => {
				console.error("EmailsMixin::archiveTrackings => ERROR", error)
				return null
			})
			if(result) return result.retour
			return null
		}
	}
}

export default EmailsMixin
